<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="row gx-16 mb-8" v-if="schedule">
      <div class="col-md-8">
        <ScheduleInfo :schedule="schedule" />
      </div>

      <div class="col-md-4 d-flex flex-column">
        <AttendButton
          v-if="
            !schedule.inPeriod || $store.getters['meetings/didFetch'](params)
          "
          :schedule="schedule"
          @attended="$store.dispatch('meetings/fetchAndInvalidate', params)"
        />
      </div>
    </div>

    <Table
      rowKey="id"
      :isLoading="$store.getters['meetings/isFetching'](params)"
      :columns="columns"
      :rows="$store.getters['meetings/get'](params)"
    >
      <template #bottom>
        <div class="border-top p-16" v-if="meta && meta.last_page > 1">
          <Pagination :current="meta.current_page" :last="meta.last_page" />
        </div>
      </template>
    </Table>
  </main>
</template>

<script>
import pick from 'lodash/pick';
import TableUtil from '../../../mixins/TableUtil';
import Schedule from '../../../store/models/Schedule';

import AttendButton from '../../../components/student/attendance/AttendButton.vue';
import ScheduleInfo from '../../../components/student/schedule/ScheduleInfo.vue';
import Table from '../../../components/table/Table.vue';

export default {
  mixins: [TableUtil],

  components: { AttendButton, ScheduleInfo, Table },

  computed: {
    params() {
      return {
        filter: pick(this.schedule, ['grade_id', 'subject_id', 'teacher_id']),
      };
    },

    columns() {
      return [
        {
          key: 'no',
          text: 'No',
          toHTML: (row, index) => this.getNumber(index),
        },
        {
          key: 'date',
          text: 'Tanggal',
          toHTML: (row) => row.dateJS.format('D MMMM YYYY'),
        },
        {
          key: 'attended',
          text: 'Status',
          toHTML: (row) =>
            row.attendances.length
              ? '<span class="text-success">Hadir</span>'
              : '<span class="text-danger">Tidak Hadir</span>',
        },
        {
          key: 'attended_at',
          text: 'Jam Masuk',
          toHTML: (row) => {
            const attendance = row.attendances[0];
            return attendance ? attendance.createdAtJS.format('HH:mm') : null;
          },
        },
      ];
    },

    schedule() {
      return Schedule.query().withAllRecursive().find(this.$route.params.id);
    },

    meta() {
      return this.$store.getters['meetings/getMeta'](this.params);
    },
  },

  methods: {
    fetch() {
      this.$store.dispatch('meetings/maybeFetch', this.params);
    },
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },

  metaInfo() {
    return { title: 'Presensi' };
  },

  async beforeMount() {
    if (!this.schedule) {
      await this.$store.dispatch('schedules/maybeFetch', {
        filter: { id: this.$route.params.id },
      });
    }

    this.fetch();

    this.$store.commit('info/setBreadcrumb', [
      { path: '/siswa/jadwal', label: 'Jadwal' },
      {
        path: this.schedule.scheduleRoutePath,
        label: this.schedule.subject.name,
      },
    ]);
  },
};
</script>
