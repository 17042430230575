<template>
  <div
    class="
      flex-grow-1
      d-flex
      justify-content-center
      align-items-center
      bg-white
      p-16
      rounded-8
      mb-16
    "
  >
    <template v-if="schedule.inPeriod">
      <template
        v-if="meeting || $store.getters['meetings/didFetch'](meetingParams)"
      >
        <span
          class="d-inline-flex align-items-center fs-20 text-success"
          v-if="attended"
        >
          <Check2SquareIcon class="me-8" /> Hadir
        </span>

        <button
          class="btn btn-primary"
          :class="{ 'is-loading': isAttending }"
          :disabled="isAttending"
          @click="attend"
          v-else
        >
          Masuk Kelas
        </button>
      </template>
    </template>

    <span
      class="d-inline-flex align-items-center fs-20 text-danger"
      v-else-if="schedule.isMissed"
    >
      <InfoSquareIcon class="me-8" /> Sudah lewat
    </span>

    <span class="d-inline-flex align-items-center fs-20 text-danger" v-else>
      <InfoSquareIcon class="me-8" /> Belum Mulai
    </span>
  </div>
</template>

<script>
import get from 'lodash/get';
import pick from 'lodash/pick';
import attendanceRepository from '../../../api/repositories/attendanceRepository';
import dayjs from '../../../bootstrap/dayjs';
import Meeting from '../../../store/models/Meeting';

import Check2SquareIcon from 'bootstrap-icons/icons/check2-square.svg';
import InfoSquareIcon from 'bootstrap-icons/icons/info-square.svg';

export default {
  components: { Check2SquareIcon, InfoSquareIcon },

  props: ['schedule'],

  data() {
    return { isAttending: false };
  },

  computed: {
    meetingParams() {
      return {
        filter: {
          date: dayjs().format('YYYY-MM-DD'),
          ...pick(this.schedule, ['grade_id', 'subject_id', 'teacher_id']),
        },
      };
    },

    meeting() {
      const query = Meeting.query().withAllRecursive();

      for (const [key, value] of Object.entries(this.meetingParams.filter)) {
        query.where(key, value);
      }

      return query.first();
    },

    attended() {
      return get(this.meeting, 'attendances.length') >= 1;
    },
  },

  methods: {
    /**
     * Fetch in period meeting.
     */
    fetchMeeting() {
      return this.$store.dispatch('meetings/fetch', this.meetingParams);
    },

    maybeFetchMeeting() {
      if (!this.meeting && this.schedule.inPeriod) {
        return this.fetchMeeting();
      }
    },

    async attend() {
      try {
        this.isAttending = true;
        await attendanceRepository.attend(this.schedule.id);
        this.$emit('attended');
        this.fetchMeeting();
      } catch (error) {
        this.$alert.requestError(error);
      } finally {
        this.isAttending = false;
      }
    },
  },

  beforeMount() {
    this.maybeFetchMeeting();
  },
};
</script>
